import React, { FC } from "react"
import styled from "styled-components"
import { HeroSectionSmallSvg, HeroSectionLargeSvg } from "../DeepDiveSvgs"

// Components
import { HeadingOne, HeadingFive } from "../../atoms/CustomPrismicTitle"

// Styles
import colors from "../../../styles/colors"
import mq from "../../../styles/breakpoints"
import { fontWeights } from "../../../styles/textStyles"

// types
import { IDeepDiveTemplateIntroProps } from "../../../prismic-types/templates/deepdive-template"

const Intro: FC<IDeepDiveTemplateIntroProps> = ({
  backgroundColor,
  description,
  eyebrowHeading,
  foregroundColor,
  heading,
  headingColor,
}) => {
  return (
    <HeroSection backgroundColor={backgroundColor}>
      <SVGWrapper>
        <SmallSvg>
          <HeroSectionSmallSvg color={foregroundColor} />
        </SmallSvg>
        <LargeSvg>
          <HeroSectionLargeSvg color={foregroundColor} />
        </LargeSvg>
      </SVGWrapper>
      <ContentSection>
        <Label color={headingColor}>{eyebrowHeading}</Label>
        <Title>{heading}</Title>
        <Paragraph>{description}</Paragraph>
      </ContentSection>
    </HeroSection>
  )
}

export default Intro

const HeroSection = styled.div<{
  backgroundColor: string
}>`
  position: relative;
  border-radius: 48px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.cultureStrategy.light};
  overflow: hidden;
`

const SVGWrapper = styled.span`
  position: absolute;
  top: 0;
  width: 100%;
`

const ContentSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 66px 44px 120px 44px;
  z-index: 99;
`

const Label = styled(HeadingFive)<{ color: string }>`
  font-weight: ${fontWeights.medium};
  color: ${({ color }) => color || colors.main["200"]};
`
const Title = styled(HeadingOne)`
  line-height: 44px;
  letter-spacing: -2px;
  text-align: center;

  ${mq.from.M`
    font-size: 64px;
    line-height: 76px;
  `}
`

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: ${fontWeights.regular};
  color: ${colors.main["100"]};
  letter-spacing: -1%;
  text-align: center;
  margin-bottom: 0;

  ${mq.from.M`
  font-size: 20px;
  line-height: 32px;
`}
`

const LargeSvg = styled.span`
  display: none;

  ${mq.from.XSM`
    display: block;
  `}
`

const SmallSvg = styled.span`
  display: block;

  ${mq.from.XSM`
    display: none;
  `}
`
